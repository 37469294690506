import React, { useState } from 'react';
import { Button, ReactInput } from 'phoenix-components';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { SnackBar } from 'ui/services';
import Alert from 'components/alert/Alert';
import { contact } from 'ui/api/products';
import { useLanguageLoader } from 'contexts/language';
import styles from './ContactUsSalvia.module.css';
import { useIsDesktop } from 'contexts/profile';

function ContactUsSalvia({
  className,
  shop,
}) {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [modal, showModal] = useState(false);
  const lang = useLanguageLoader();
  const catalogs = (shop?.catalogs || []).filter(x => x.url);
  const resetForm = () => {

    setName('');
    setEmail('');
    setPhone('');
    setMessage('');

  };
  const isDesktop = useIsDesktop();

  return (
    <div className={cx(styles.section, className)} id="contactUs">
      {modal && (
        <Alert
          onClose={() => showModal(false)}
          text="Thanks for sharing your details. We will contact you shortly."
        />
      )}
      <div className={styles.head}>
        <span></span>
        <span>{lang('store.menu.contactUs')}</span>
      </div>
      <div className={styles.formContainer}>
        {isDesktop && catalogs.length >= 4 &&  (
          <div className={styles.imageMain}>
            {catalogs
              .slice(0, 5)
              .map(catalog => (
                <img
                  className={cx(styles.images, { [styles.noCatalogImg]: !catalog.url })}
                  key={catalog.label}
                  src={catalog.url}
                  alt=""
                />
              ))}
          </div>
        )}

        <form
          Validate
          autoComplete="off"
          id="form"
          className={styles.form}
          onSubmit={async (e) => {

            e.preventDefault();
            const payload = {
              name,
              email,
              phone,
              message,
            };
            try {

              await contact(shop._id, payload);
              // setAddress(copy);
              showModal(true);
              resetForm();

            } catch (exception) {

              SnackBar.showError(exception);

            }

          }}
        >
          <ReactInput
            inputClass={styles.inputClass}
            value={name}
            setValue={setName}
            required={true}
            errorTextClass={styles.errClass}
            nestedInputClass={styles.nestedInputClass}
            placeholder={lang('store.contact.name.label')}
          />
          <ReactInput
            inputClass={styles.inputClass}
            value={email}
            setValue={setEmail}
            required={true}
            errorTextClass={styles.errClass}
            nestedInputClass={styles.nestedInputClass}
            placeholder={lang('store.contact.email.label')}
          />
          <ReactInput
            inputClass={styles.inputClass}
            value={phone}
            setValue={setPhone}
            required={true}
            errorTextClass={styles.errClass}
            nestedInputClass={styles.nestedInputClass}
            placeholder={lang('store.contact.phone.label')}
          />
          <ReactInput
            type="textarea"
            rows={2}
            required={true}
            inputClass={styles.inputClass}
            nestedInputClass={styles.nestedInputClass}
            value={message}
            errorTextClass={styles.errClass}
            setValue={setMessage}
            placeholder={lang('store.contact.message.placeholder')}
          />
          <div className={styles.flexCenter}>
            <Button
              className={styles.button}
              type="submit"
              size="large"
              primary={false}
              fullWidth={true}
              label={lang('buttons.submit')}
              onClick={() => {
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );

}

ContactUsSalvia.propTypes = {
  shop: PropTypes.object.isRequired,
  className: PropTypes.string,
};

ContactUsSalvia.defaultProps = {
  className: '',
};

export default ContactUsSalvia;
