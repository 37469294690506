import React, { useState } from 'react';
import { locationIcon, defaultPpIcon, SalviaPrevIcon, SalviaNextIcon } from 'components/shared/Icons/Shop';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Clickable } from 'phoenix-components';
import { useIsDesktop } from 'contexts/profile';
import ShopUtils from 'ui/lib/shopUtils';
import styles from './Testimonials.module.css'
import { useKeenSlider } from 'keen-slider/react';



export default function Testimonials({ shop }) {
  const isDesktop = useIsDesktop();
  const data = ShopUtils.getTestimonials(shop, 'testimonials');
  const [currentSlide, setCurrentSlide] = useState(0);

  const perView = isDesktop ? 3 : 1;

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      slides: {
        perView: perView,
        spacing: 20,
      }
    },
  );


  const activeIndex = isDesktop ? currentSlide + 1: currentSlide;
  const testimonials = (data || []).filter((item) => item.enabled === true);

  if(testimonials.length === 0){
    return null;
  }


  const feedTitle = ShopUtils.getTitle(shop, 'testimonials');
  const savedTitle = "Testimonials" === feedTitle ?
    "Customer Testimonials" : feedTitle;


  return (<div className={cx(styles.section)} id="shopTestimonials">
    <div
      className={styles.head}
    >
      <span></span>
      <span>{savedTitle.toUpperCase()}</span>
    </div>

    <div className={styles.sliderContainer}>
      {isDesktop && (
        <Clickable
          className={cx({
            'opacity-30': currentSlide === 0,
          })}
          onClick={(e) => {

            e.stopPropagation();
            instanceRef.current.prev();

          }}
        >
          <SalviaPrevIcon />
        </Clickable>
      )}
      <div
        ref={sliderRef}
        className={'keen-slider'}
      >
        {testimonials.map((el, i) => (
          <div
            key={i}
            className={cx('keen-slider__slide',{
              [styles.active]: activeIndex === i,
            })}
          >
            <div className={styles.testimonialContainer}>
              <div className={styles.topContainer}>
                {el.type === 'message' ?
                  <div className={styles.message}>{el.message}</div>
                  : <div className={styles.imageCon}>
                    <img src={el.testimonialImage} alt="" className={styles.testimonialImage} />
                  </div>}
              </div>
              <div className={styles.bottomContainer}>
                <div className={styles.section1}>
                  {el.customerImage === '' ? defaultPpIcon() :
                    <img src={el.customerImage} alt="" className={styles.profilePic} />
                  }
                </div>
                <div className={styles.details}>
                  <div className={styles.name}>{el.name} </div>
                  <div>
                    {el.designation !== '' &&
                      <span className={styles.des}>{el.designation}</span>
                    }</div>
                  {el.location !== '' &&
                    <div className={styles.location}>{locationIcon()}
                      <div className={styles.locationName}>{el.location}</div>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {isDesktop && (
        <Clickable
          className={cx({
            'opacity-30': currentSlide === testimonials.length - 1,
          })}
          onClick={(e) => {

            e.stopPropagation();
            instanceRef.current.next();

          }}
        >
          <SalviaNextIcon />
        </Clickable>
      )}
    </div>
  </div>)
}
Testimonials.propTypes = {
  shop: PropTypes.object.isRequired,
};

Testimonials.defaultProps = {};
