import Testimonials from 'components/store/salvia/Testimonials';
import StoreInfoSalvia from 'components/store/salvia/StoreInfoSalvia';
import StoreTimingsSalvia from 'components/store/salvia/StoreTimingsSalvia';
import ContactUsSalvia from 'components/store/salvia/ContactUsSalvia';
import FooterSalvia from 'components/store/salvia/FooterSalvia';
import React from 'react';
import ShopUtils from 'ui/lib/shopUtils';

export default function Footer({ shop }) {
  const isTestimonials = shop?.uiConfig?.testimonials?.length > 0;
  const testimonialsEnabled = ShopUtils.isSectionEnabled(shop, 'testimonials');
  const shopTimingsEnabled = ShopUtils.isSectionEnabled(shop, 'shopTimings');
  return (
    <>
      {isTestimonials && testimonialsEnabled && <Testimonials shop={shop}/>}
      <StoreInfoSalvia shop={shop} footerInfo={shop?.footerInfo}/>
      {shopTimingsEnabled && <StoreTimingsSalvia timings={shop?.storeTimings || {}} shop={shop}/>}
      <ContactUsSalvia shop={shop} />
      <FooterSalvia shop={shop}/>
    </>
  );
}
