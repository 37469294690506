import React, { useState } from "react";
import styles from "./FooterSalvia.module.css";
import { getShopIcon } from "components/utils/icon";
import { useLanguageLoader } from "contexts/language";
import { Clickable } from "phoenix-components";
import { useRouter } from "next/router";
import { useIsCustomDomain } from "contexts/profile";
import ShopUtils from "ui/lib/shopUtils";
import PropTypes from "prop-types";
import Alert from "components/alert/Alert";

function FooterSalvia({ shop, dataId, variant }) {
  const facebook = "/images/followUs/facebook.svg";
  const twitter = "/images/followUs/twitter.svg";
  const instagram = "/images/followUs/instagram.svg";
  const youtube = "/images/followUs/youtube.svg";
  const pinterest = "/images/followUs/pinterest.svg";
  const watsapp = "/images/followUs/watsapp.svg";

  const paypal = "/images/paymentPartners/paypalSalvia.svg";
  const visa = "/images/paymentPartners/visaSalvia.svg";
  const maestro = "/images/paymentPartners/maestroSalvia.svg";
  const amex = "/images/paymentPartners/amexSalvia.svg";
  const upi = "/images/paymentPartners/upiSalvia.svg";
  const paytm = "/images/paymentPartners/paytmSalvia.svg";
  const gpay = "/images/paymentPartners/gpaySalvia.svg";

  const [aboutModal, setShowAboutModal] = useState(false);
  const [policyModal, setShowPolicyModal] = useState(false);

  const onlineEnabled = shop?.paymentModes?.online?.enabled;
  const bankEnabled = shop?.paymentModes?.custompayment?.enabled;
  const cashEnabled = shop?.paymentModes?.cod?.enabled;
  const currCountry = shop?.country;
  const regex = /(<([^>]+)>)/gi;
  const isFilledAboutUs = !!shop?.about?.replace(regex, "").length > 0;
  const aboutUsEnabled = ShopUtils.isSectionEnabled(shop, "aboutUs");
  const shopPoliciesEnabled = ShopUtils.isSectionEnabled(shop, "shopPolicies");
  const isFilledTerms = shop?.tncs?.length > 0;

  const paymentMethodsEnabled = ShopUtils.isSectionEnabled(
    shop,
    "paymentMethods"
  );

  const availableOnlinePaymentMethods = [paypal, maestro, amex, visa, gpay];

  if (currCountry === "india") {
    availableOnlinePaymentMethods.push(paytm, upi);
  }

  const mapper = {
    instagram: instagram,
    whatsapp: watsapp,
    pinterest: pinterest,
    twitter: twitter,
    youtube: youtube,
    facebook: facebook,
  };

  function addhttp(item) {
    let { url } = item;
    if (item?.name === "whatsapp") {
      window.open(`https://api.whatsapp.com/send/?phone=${url}`, "_blank");
      return;
    }
    if (!/^(?:f|ht)tps?\/\//.test(url)) {
      url = `${url}`;
    }
    window.open(url, "_blank");
  }

  const lang = useLanguageLoader();
  const router = useRouter();
  const isCustomDomain = useIsCustomDomain();
  const ppTncsEnabled = ShopUtils.isSectionEnabled(shop, "ppTncs");
  const tncsEnabled = ShopUtils.isSectionEnabled(shop, "tncs");
  const socialMediaEnabled = ShopUtils.isSectionEnabled(shop, "socialMedia");
  const links = shop?.linkedAccounts || [];
  const onClick = (isPolicy = true) => {
    if (isPolicy) {
      router.push({
        pathname: isCustomDomain
          ? "/privacypolicy"
          : `/${shop.slug}/privacypolicy`,
      });
      return;
    }
    router.push({
      pathname: isCustomDomain ? "/terms" : `/${shop.slug}/terms`,
    });
  };

  const feedTitleAbout = ShopUtils.getTitle(shop, "aboutUs");
  const savedTitleAbout =
    lang("store.menu.aboutUs") === feedTitleAbout
      ? lang("store.menu.aboutUs")
      : feedTitleAbout;

  const feedTitlePolicies = ShopUtils.getTitle(shop, "shopPolicies");
  const savedTitlePolicies =
    lang("store.menu.termsPolicies") === feedTitlePolicies
      ? lang("store.menu.termsPolicies")
      : feedTitlePolicies;

  const feedTitle = ShopUtils.getTitle(shop, "ppTncs");
  const savedTitle =
    "Privacy Policy" === feedTitle ? "Privacy Policy" : feedTitle;

  const feedTitleTncs = ShopUtils.getTitle(shop, "tncs");
  const savedTitleTncs =
    "Terms & Conditions" === feedTitleTncs
      ? "Terms & Conditions"
      : feedTitleTncs;

  return (
    <div className={styles.footer}>
      <div className={styles.shopIcon} shop={shop}>
        <img
          src={getShopIcon(shop?.icon?.[0])}
          className={styles.userIcon}
          alt=""
          data-id={dataId}
        />
      </div>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: shop?.description }}
      ></div>
      <div className={styles.links}>
        <div>
          <Clickable
            className={styles.home}
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              await router.push({
                pathname: isCustomDomain ? "/" : `/${shop?.slug}`,
              });
            }}
          >
            {lang("store.menu.home")}
          </Clickable>
        </div>{" "}
        {aboutUsEnabled && isFilledAboutUs && (
          <div>
            <Clickable
              className={styles.aboutUs}
              onClick={() => setShowAboutModal(true)}
              id="shopAboutUs"
            >
              {savedTitleAbout}
            </Clickable>
            {aboutModal && (
              <Alert
                onClose={() => setShowAboutModal(false)}
                text={shop?.about?.replace(regex, "")}
              />
            )}{" "}
          </div>
        )}
        {shopPoliciesEnabled && isFilledTerms && (
          <div>
            <Clickable
              className={styles.aboutUs}
              onClick={() => setShowPolicyModal(true)}
              id="shopTnc"
            >
              {savedTitlePolicies}
            </Clickable>
            {policyModal && (
              <Alert
                onClose={() => setShowPolicyModal(false)}
                text={shop?.tncs.map((s, i) => (
                  <div key={i} dangerouslySetInnerHTML={{ __html: s }}></div>
                ))}
              />
            )}{" "}
          </div>
        )}
        {tncsEnabled && variant === "complete" && (
          <>
            <div>
              <Clickable
                onClick={() => onClick(false)}
                className={styles.terms}
              >
                {savedTitleTncs}
              </Clickable>
            </div>{" "}
          </>
        )}
        {ppTncsEnabled && variant === "complete" && (
          <>
            <div>
              <Clickable
                onClick={() => onClick(true)}
                className={styles.policy}
              >
                {savedTitle}
              </Clickable>
            </div>
          </>
        )}
      </div>
      <div className={styles.socialIcons}>
        {socialMediaEnabled && links.length > 0 && (
          <>
            {links.map((x, i) => (
              <Clickable
                key={i}
                className={styles.card}
                onClick={() => addhttp(x)}
              >
                <img src={mapper[x.name]} alt="" />
              </Clickable>
            ))}
          </>
        )}
      </div>
      <div className={styles.lineH}></div>
      {paymentMethodsEnabled && (
        <div className={styles.paymentIcons}>
          {onlineEnabled && (
            <>
              <div className={styles.online}>
                {availableOnlinePaymentMethods.map((x, i) => (
                  <img key={i} className={styles.card1} src={x} alt="" />
                ))}
              </div>
              <div className={styles.lineV}></div>
            </>
          )}
          {cashEnabled && (
            <>
              <div className={styles.cash}>{lang("store.menu.cash")}</div>
              <div className={styles.lineV}></div>
            </>
          )}
          {bankEnabled && (
            <>
              <div className={styles.bank}>
                {shop?.paymentModes?.custompayment.configured.map((s, i) => {
                  if (s.status) {
                    return (
                      <div>
                        {i !== 0 && ", "}
                        {s.mode}
                      </div>
                    );
                  }
                })}
              </div>
            </>
          )}
        </div>
      )}
      <div className={styles.copyRight}>
        {lang("store.headings.copyRight")} &copy; {shop.name}
      </div>
    </div>
  );
}

FooterSalvia.propTypes = {
  shop: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

FooterSalvia.defaultProps = {
  variant: "complete",
};

export default FooterSalvia;
