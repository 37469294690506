import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Grid} from '@mui/material';
import styles from './StoreTimingsSalvia.module.css';
import { useLanguageLoader } from 'contexts/language';
import dayjs from 'dayjs';
import ShopUtils from 'ui/lib/shopUtils';


function StoreTimings({timings, shop}) {
  const lang = useLanguageLoader();
  const items = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  const getTime = (day) => {

    const smallCap = day?.toLowerCase();
    if (timings[smallCap]) {

      const exp = 'h:mm A';
      const {opensAt, closesAt, status} = timings[smallCap];
      const opens = dayjs(opensAt, 'HH:mm').format(exp);
      const closes = dayjs(closesAt, 'HH:mm').format(exp);
      if (status === 'live') {

        return opens !== closes ? `${opens} - ${closes}` : '24 Hours';

      }
      return 'Closed';

    }
    return '24 Hours';

  };
  const feedTitleTime = ShopUtils.getTitle(shop, 'shopTimings');
  const savedTitleTime = feedTitleTime === 'Shop Timings' ?
    lang('store.menu.storeTimings') : feedTitleTime;

  return (
    <div className={cx(styles.section)} id="shopStoreTimings">
      <div className={styles.main}>
        <div className={styles.container}>
          <div className={styles.head}>
            <span>{savedTitleTime}</span>
          </div>
          <div className={styles.body}>
            <Grid container spacing={3} className={styles.item}>
              {items.map(x => (
                <>
                  <Grid item xs={4} className={styles.day}>{lang(`days.${x}`)}</Grid>
                  <Grid item xs={4} className={styles.dash}>{'-'}</Grid>
                  <Grid item xs={4} className={styles.time}>
                    <div className={styles.time}>{getTime(x)}</div>
                  </Grid>
                </>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );

}

StoreTimings.propTypes = {
  timings: PropTypes.object.isRequired,
};

export default StoreTimings;
