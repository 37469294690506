import React from 'react';
import PropTypes from 'prop-types';
import {
  addressIcon,
  mailIcon,
  orderIconSalvia,
  paymentIconSalvia,
  phoneIcon,
  shippingIconSalvia,
} from 'components/shared/Icons/StoreInfo';
import { isEmpty } from 'ui/lodash';
import { useLanguageLoader } from 'contexts/language';
import cx from 'classnames';
import ShopUtils from 'ui/lib/shopUtils';
import styles from './StoreInfoSalvia.module.css';

function StoreInfoSalvia({ footerInfo, shop }) {

  const orderEnabled = (isEmpty(footerInfo?.order) || footerInfo?.order?.enabled);

  const paymentEnabled = (isEmpty(footerInfo?.payment) || footerInfo?.payment?.enabled);

  const shippingEnabled = (isEmpty(footerInfo?.shipping) || footerInfo?.shipping?.enabled);
  const lang = useLanguageLoader();

  const storeInformationEnabled = ShopUtils.isSectionEnabled(shop, 'storeInformation');
  const otherInformationEnabled = ShopUtils.isSectionEnabled(shop, 'otherInformation');
  const feedTitle = ShopUtils.getTitle(shop, 'storeInformation');
  const savedTitle = lang('store.headings.storeInformation') === feedTitle ?
    lang('store.headings.storeInformation') : feedTitle;
  return (
    storeInformationEnabled || otherInformationEnabled ? (
      <div className={styles.mainContainer} id="shopStoreInfo">
        <div className={styles.heading}>{savedTitle}</div>
        {storeInformationEnabled &&  (footerInfo?.store?.enabled === true) && (
          <div className={styles.preview}>
            {footerInfo?.store?.email?.length > 0 && (
              <>
                <div className={styles.flex}>
                  <span>{mailIcon()}</span>
                  <div className={styles.tile}>{footerInfo?.store?.email}</div>
                </div>

              </>
            )}
            {footerInfo?.store?.phone?.length > 0 && (
              <>
                <div className={styles.flex}>
                  <span>{phoneIcon()}</span>
                  <div className={styles.tile}>{footerInfo?.store?.phone}</div>
                </div>

              </>
            )}
            {footerInfo?.store?.address?.length > 0 && (
              <div className={styles.flex}>
                <span>{addressIcon()}</span>
                <div
                  dangerouslySetInnerHTML={{ __html: footerInfo?.store?.address }}
                  className={styles.tile2}>
                </div>
              </div>
            )}
          </div>
        )}
        {otherInformationEnabled && (
          <>
            {(orderEnabled || paymentEnabled || shippingEnabled) && (
              <div className={styles.main}>
                <div className={cx(styles.grey, styles.storeButtons)}>
                  {shippingEnabled && (
                    <div className={styles.box}>
                      <div>
                        <div className={styles.title}>
                          <span className={styles.shipDesk}>{shippingIconSalvia()}</span>
                          <div>{footerInfo?.shipping?.title || lang('store.headings.fastShipping')}</div>
                        </div>
                        <div className={styles.desc}>
                          {footerInfo?.shipping?.subTitle?.length > 0 && (
                            footerInfo?.shipping?.subTitle
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {paymentEnabled && (
                    <div className={styles.box}>
                      <div>
                        <div className={styles.title}>
                          <span className={styles.payDesk}>{paymentIconSalvia()}</span>
                          <div>{footerInfo?.payment?.title || lang('store.headings.seamlessPayments')}</div>
                        </div>
                        <div className={styles.desc}>
                          {footerInfo?.payment?.subTitle?.length > 0 && (
                            (footerInfo?.payment?.subTitle)
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {orderEnabled && (
                    <div className={styles.box}>
                      <div>
                        <div className={styles.title}>
                          <span className={styles.orderDesk}>{orderIconSalvia()}</span>
                          <div>{footerInfo?.order?.title || lang('store.headings.orderTracking')}</div>
                        </div>
                        <div className={styles.desc}>
                          {footerInfo?.order?.subTitle?.length > 0 && (
                            (footerInfo?.order?.subTitle)
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    ) : null
  );
}

StoreInfoSalvia.propTypes = {
  footerInfo: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
};

export default StoreInfoSalvia;
